import {Attribute, Component} from "./definitions/types";

export const getComponentTypeAndName = (component: Component): string => {
    const nameAttribute = component.attributes.find(attr => attr.name === "name");
    const name = nameAttribute ? nameAttribute.value : "unknown";
    return `${name} (${component.name})`;
    return `${name}`;
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line eqeqeq,no-mixed-operators
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

/**
 * Returns the Attributes as a hash. attrName: value
 * To use like get(compAttributes).name;
 * @param attributes
 */
export const attributesToHash = (attributes: Attribute[]):any => {
    return attributes.reduce((acc, attribute) => {
        // @ts-ignore
        acc[attribute.name] = attribute.value;

        return acc;
    }, {});
}
