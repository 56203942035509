import {Attribute, Component} from "./definitions/types";
import React, {useContext, useState, useEffect} from "react";
import {PtahSelect} from "./PtahSelect/PtahSelect";
import {EditorContext} from "../App";

type propsType = {
    component: Component,
    onComplete: (component: Component) => void
}

const FULL_STOP: Attribute = {
    prefix: ".",
    name: "Full stop",
    required: false
};

export const FillComponentsAttributes = (props: propsType) => {
    const [component, setComponent] = useState(props.component);
    const context = useContext(EditorContext);

    const getAttrToFill = (skip?: Attribute) => component.attributes
        .find( (attr: Attribute) => (attr.value === undefined && attr.required) && skip?.name !== attr.name);

    const [attrToFill, setAttrToFill] = useState(getAttrToFill());

    const optionalAttrToFill = component
        .attributes
        .filter( (attr: Attribute) => attr.value === undefined && !attr.required)
        .reduce((sum, attr, index, arr) => {
            // @ts-ignore
            sum.push({ label: attr.name, value: attr as any });

            if (arr.length) {
                // @ts-ignore
                sum.push({ label: ".", value: FULL_STOP });
            }

            return sum;
        }, []);

    const stop = () => {
        props.onComplete(component);
        setAttrToFill(FULL_STOP as any);
    }

    // all is filled
    if (optionalAttrToFill.length === 0 && !attrToFill) {
        stop();
    }

    const onInputEnter = (value: string, attribute: Attribute) => {
        if (value === "") {
            return;
        }

        setComponent((component) => {
            const copy: Component = {
                ...component,
                attributes: JSON.parse(JSON.stringify(component.attributes)) //copy, will loose all functions if any
            };

            copy.attributes = copy.attributes.map(attr => {
                if (attr.name === attribute.name) {
                    attr.value = value;
                }

                return attr;
            });

            return copy;
        });

        setAttrToFill(() => {
            return getAttrToFill(attribute);
        });
    };

    const onAttrSelect = (selectedOptionalAttribute: Attribute | string) => {
        if (selectedOptionalAttribute === FULL_STOP) {
            stop();
            return;
        }

        setAttrToFill(selectedOptionalAttribute as any);
    }

    const selectOptionalAttributeFromSelect = !attrToFill && !!optionalAttrToFill.length;
    const hasAttributeToFill = attrToFill && attrToFill !== FULL_STOP;
    const fullStopAttributeIsSelected = attrToFill === FULL_STOP;
    const allRequiredAndOptionalOptionsAreFilled = !attrToFill && !optionalAttrToFill.length;


    useEffect(() => {
        if (hasAttributeToFill || fullStopAttributeIsSelected || allRequiredAndOptionalOptionsAreFilled) {
            context.setOptions([]);
        }

        if (hasAttributeToFill) {
            console.log("+ Attribute filler set OnMatch");
            context.onMatch((matchWord: any) => {
                if (attrToFill) {
                    onInputEnter(matchWord, attrToFill);
                }
            });
        }

    }, [hasAttributeToFill, fullStopAttributeIsSelected, allRequiredAndOptionalOptionsAreFilled, attrToFill]);

    // @ts-ignore
    return (
        <span className="attr-fill">
            {
                component
                    .attributes
                    .filter( (attr: Attribute) => attr.value !== undefined)
                    .map((attr: Attribute) => {
                        const classes = {
                            name: "attr-name",
                            text: "attr-text",
                            classNames: "attr-classNames",
                            default: "attr-value"
                        };

                        // @ts-ignore
                        const className: string = classes[attr.name] || classes.default;

                        return (
                            <span
                                className="attr-filled"
                                key={Math.random()}
                            >
                                &nbsp;{ attr.prefix }&nbsp;
                                <span className={className}>{ attr.value }</span>
                            </span>
                        )
                    })
            }

            {
                (hasAttributeToFill) && (
                    <span className="attr-input">
                        &nbsp;{ (attrToFill as any).prefix }&nbsp;
                        <input
                            key={Math.random()}
                            autoFocus
                            onKeyDown={(e: any) => {
                                e.key === "Enter" && onInputEnter(e.target.value, attrToFill as any)
                            }}
                        />
                    </span>
                )
            }

            {
                (selectOptionalAttributeFromSelect) && (
                    <span className="select">&nbsp;
                        <PtahSelect
                            key={Math.random()}
                            onChange={(e: any) => onAttrSelect(e.value)}
                            options={optionalAttrToFill}
                        />
                    </span>
                )
            }

            {
                (fullStopAttributeIsSelected) && (
                    <span className="end-of-line">.</span>
                )
            }

            {
                (allRequiredAndOptionalOptionsAreFilled) && (
                    <span className="end-of-line">.</span>
                )
            }
        </span>
    )
}
