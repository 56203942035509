import React, {useState} from 'react';
import './styles.css';

import {Component} from './definitions/types';
import {CreateTheRootComponent} from "./CreateTheRootComponent";
import {CreateChildComponent} from "./CreateChildComponent";
import {PreviewTypes, Tools} from "./Tools/Tools";
import {RenderUI} from "./RenderUI/RenderUI";
import {RenderCode} from "./RenderCode/RenderCode";
import {Header} from "./Header/Header";

import {DEFINITIONS} from "./definitions/definitions";
import {Dictaphone} from "./Dictaphone/Dictaphone.js";
import {EditorContext} from "../App";

function Editor() {
    const componentsList: Component[] = [];
    const [completedSteps, setCompletedSteps] = useState(componentsList);
    const [selectedPreviewType, setSelectedPreviewType] = useState(PreviewTypes.UI);

    const componentCompleted = (component: Component) => {
        setCompletedSteps((completedSteps) => {
            return completedSteps.concat(component);
        });
    }

    const stepsToComplete = (components: Component[]) => {
        const steps = [];

        for (let i=0; i < components.length; i++) {
            steps.push(
                <li className="step" key={"step-" + i}>
                    In the&nbsp;
                    <CreateChildComponent
                        definitions={DEFINITIONS}
                        components={completedSteps}
                        onComplete={componentCompleted}
                    />
                </li>
            );
        }

        return steps;
    }

    // console.log("RENDER EDITOR");

    return (
        <>
            <Header />
            <div className="content">
                <div className="editor">
                    <ul className="steps">
                        <li className="step" key="rootComponent">
                            <CreateTheRootComponent
                                definitions={DEFINITIONS}
                                onComplete={componentCompleted}
                            />
                        </li>
                        { completedSteps.length !== 0 && stepsToComplete(completedSteps) }
                    </ul>

                    <div className="mic">
                        <Dictaphone />
                    </div>
                </div>
                <div className="preview" key="ptah-preview">
                    { selectedPreviewType === PreviewTypes.UI && (
                        <RenderUI steps={completedSteps} />
                    ) }

                    { selectedPreviewType === PreviewTypes.CODE && (
                        <RenderCode steps={completedSteps} />
                    ) }
                </div>
                <Tools
                    selected={selectedPreviewType}
                    onChange={(type: PreviewTypes) => setSelectedPreviewType(type)}
                />
            </div>
        </>
    );
}

export default Editor;
