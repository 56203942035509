import React, {useContext, useState} from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import {ReactComponent as MicrophoneIcon} from "../assets/microphone-line.svg";
import {EditorContext} from "../../App";

export const Dictaphone = () => {
    const context = useContext(EditorContext);
    const [isListening, setIsListening] = useState(false);
    const options = context.options;

    const onMatch = (word) => {
       context.match(word);
       resetTranscript();
    }

    const commands = options.map(option => {
        const opt = option;

        return {
            command: option,
            callback: () => { onMatch(opt) },
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.5,
            // matchInterim: true
        }
    });

    const { transcript, resetTranscript } = useSpeechRecognition({ commands });

    const toggleListening = () => {
        if (!isListening) {
            setIsListening(true);
            SpeechRecognition.startListening({
                continuous: true
            });
        } else {
            setIsListening(false);
            SpeechRecognition.stopListening();
        }
    }

    if (options.length === 0 && transcript.length !== 0) {
        onMatch(transcript);
        resetTranscript();
    }

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null
    }

    let iconClassName = "icon";
    let dictaphoneClassName = "dictaphone";

    if (isListening) {
        iconClassName += " active";
        dictaphoneClassName += " active";
    }


    return (
        <>
            <div className={dictaphoneClassName}>
                <div className={iconClassName} onClick={toggleListening}><MicrophoneIcon width="14px" height="14px" /></div>
                { !isListening &&
                     <div className="info">
                         Press <span className="toggle-btn" onClick={toggleListening}>the microphone</span> to enable the god mode (voice input).</div>
                }

                { isListening && !!options.length &&
                    <div className="info">Options: { options.join(", ") }</div>
                }

                { isListening && !options.length &&
                    <div className="info">Free input</div>
                }
            </div>
            { isListening && <>
                <div className={dictaphoneClassName}>
                    <div className="icon" />
                    <div className="info">
                        Get from the voice api: "{ transcript }"<br/><br/>
                    </div>
                </div>
            </>
            }
        </>
    )
}
