import {Component} from "./definitions/types";
import React, {useState} from "react";
import {SelectExistingComponent} from "./SelectExistingComponent";
import {SelectComponentFromDefinitions} from "./SelectComponentFromDefinitions";
import {FillComponentsAttributes} from "./FillComponentsAttributes";
import {getComponentTypeAndName, uuidv4} from "./utils";

type PropTypes = {
    definitions: Component[],
    components: Component[],
    onComplete: (component: Component) => void,
}

export const CreateChildComponent = (props: PropTypes) => {
    const [parentComponent, setParentComponent] = useState<Component | null>(null);
    const [selectedComponent, setSelectedComponent] = useState<Component | null>(null);

    const onComplete = (filledComponent: Component) => {
        filledComponent.parent = parentComponent as Component;
        filledComponent.id = uuidv4();
        props.onComplete(filledComponent);
    }

    return <>
        { !parentComponent
            ? <SelectExistingComponent components={props.components} onSelect={setParentComponent as any} />
            : <span className="component-type-and-name">{ getComponentTypeAndName(parentComponent as any) }</span>
        }

        { !!parentComponent && <>
            <span>&nbsp;add a&nbsp;</span>
            { !selectedComponent
                ? <SelectComponentFromDefinitions
                    parent={parentComponent}
                    definitions={props.definitions}
                    onSelect={setSelectedComponent as any}
                />
                : <span className="component-type">{ (selectedComponent as any).name }</span>
            }
        </>
        }

        { !!selectedComponent && (
            <FillComponentsAttributes component={selectedComponent as any} onComplete={onComplete} />
        ) }
    </>
}
