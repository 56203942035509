import React, {ReactChildren, useState} from 'react';

import "./styles.css";

import {ReactComponent as CloseIcon} from "./assets/times-line.svg";

type PropTypes = {
    isOpen: boolean,
    title: string,
    onClose: () => void,
    children?: any
}

export const Modal = (props: PropTypes) => {
    if (!props.isOpen) { return null }

    return (
        <div className="ptah-overlay-modal">
            <div className="ptaa-modal">
                <header>
                    <div className="ptaa-modal-title">{props.title}</div>
                    <div title="close modal" className="close" onClick={props.onClose}>
                        <CloseIcon width="16" height="16" title="close modal" />
                    </div>
                </header>
                <section>
                    <div className="ptaa-scroll-container">
                        {props.children}
                    </div>
                </section>
            </div>
        </div>
    );
}
