import React from "react";
import Select from "react-select";

const selectStyles = {
    container: () => ({
        display: 'inline-block',
        width: '100px',
        position: 'relative',
        background: 'var(--input-bg)',
        borderRadius: "4px",
        padding: "1px 3px",
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    dropdownIndicator: () => ({
        display: 'none'
    }),
    control: (state) => ({
        border: 0,
    }),
    valueContainer: () => ({
        padding: 0,
        margin: 0
    }),
    menuList: () => ({
        color: "#333"
    }),
    input: () => ({
        margin: 0
    })
}

export const PtahSelect = (props) => <Select
    value=""
    onChange={props.onChange}
    closeMenuOnSelect={true}
    options={props.options}
    placeholder=""
    onKeyDown={props.onKeyDown}
    autoFocus={true}
    styles={selectStyles}
/>
