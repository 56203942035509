import React, {useState} from "react";
import {ReactComponent as Logo} from "./assets/logo.svg";
import {ReactComponent as MoonIcon} from "./assets/moon.svg";
import {ReactComponent as SunIcon} from "./assets/sun.svg";
import {Modal} from "../Modal/Modal";

import "./styles.css";

enum ModalTypes {
    LEGEND,
    ABOUT
}

// TODO: not like this :D
const setThemeBodyClass = (isLightTheme: boolean) => {
    localStorage.setItem("isThemeLight", JSON.stringify(!isLightTheme));

    if (isLightTheme) {
        // @ts-ignore
        document.querySelector('body').classList.add("dark");
        // @ts-ignore
        document.querySelector('body').classList.remove("light");
    } else {
        // @ts-ignore
        document.querySelector('body').classList.add("light");
        // @ts-ignore
        document.querySelector('body').classList.remove("dark");
    }
}

const isTheInitialThemeLight = JSON.parse(localStorage.getItem("isThemeLight") || "true");
setThemeBodyClass(!isTheInitialThemeLight);

export const Header = () => {
    const [openedModal, setOpenedModal] = useState();
    const [isLightTheme, setIsLightTheme] = useState(isTheInitialThemeLight);

    const onClose = () => {
        setOpenedModal(undefined);
    }

    const onThemeToggle = () => {
        setIsLightTheme(!isLightTheme);
        setThemeBodyClass(isLightTheme);
    }

    return (
        <>
            <Modal title="LEGEND: input colors explained" isOpen={openedModal === ModalTypes.LEGEND} onClose={onClose}>
                <div>
                    <h4>Color legend:</h4>
                    <div>
                        <div className="color-type"><span className="color-component-type" /> Component type</div>
                        <div className="color-type"><span className="color-component-type-parent" />  Component selected for parent</div>
                        <div className="color-type"><span className="color-component-type-name" />  Name attribute</div>
                        <div className="color-type"><span className="color-component-type-text" />  Text attribute</div>
                    </div>
                </div>
            </Modal>

            <Modal title="ABOUT: What and why is PTAA" isOpen={openedModal === ModalTypes.ABOUT} onClose={onClose}>
                <div>
                    <p>
                    Named after the Egyptian creator god who existed before all other things and, by his will, spoke the world into existence. PTAH will empower everyone involved in product development to do just that:
                    </p>

                    <strong>“speak the ui into existence”</strong>

                    <p>The primary purpose of this project concept is to ease the work and reduce the efforts required by:</p>
                    <ul>
                        <li>Developers - with using our tool everyone would be (enabled/empowered) to create a basic structure for new and otherwise complex components and views within minutes. Thus we aim to reduce the initial effort by focusing the developer's attention to the implementation logic, specification and other significant aspects of development.</li>
                        <li>Product owners - as unbelievable as it may sound, this tool may help them too. Most of us have been in a meeting with PO or some technically competent member of the team with little to none experience with front-end development. Imagine if the PO or the developer were able to come up with a quick mockup during the meeting(as more often than not the designer/UX member of the team is not present). That would really help reduce the time spent on conceptualizing and drawing some abstract diagrams as well as the number of meetings needed to agree on something final. Also, the export feature would allow anyone to send the generated html markup to the member of the team responsible for the development of the component and serve as a base for their further development.</li>
                    </ul>

                    <p>
                        Let us now go a bit into details of how exactly are we going to achieve our purpose.<br/>
                        As already mentioned we would focus on two use cases:
                    </p>


                    <h4>User text input</h4>
                    <p>
                    In this scenario, the user would interact with the editor of our tool by selecting options from pure predefined lists of DOM elements, components (provided by Clarity as part of the first phase) and element attributes. For further details regarding the way this editor would look like you may refer to the design provided. To contribute further to better UX we would be displaying a live preview of what the user is currently constructing.
                    Whenever the user is satisfied with the result, they would be able to export the generated HTML markup for constructing the displayed DOM and use it as a base for their components.
                    </p>

                    <h4>Speech input</h4>
                    <p>The primary difference between this scenario and the one already described is that in this case we rely on the user input coming from the speech recognition of Web Speech API provided by the browser. That would allow us to rely on an already working and tested solution that we would expect to generate as clear text from the speech as possible and hence allow us to construct the proper DOM tree expected by the user.</p>

                    <p>We have decided to use React as the library for our solution based on the following advantages it has: better performance and less complexity during the setup of the final solution. We do take some inspiration in the way React works in our implementation for the HTML elements construction.</p>

                    <h4>Milestones:</h4>
                    <p>
                        I: Functionality for creating a limited number of components using the UI. <br />
                        II: Expand the available components and add voice commands support.<br />
                        III: Apart from UI we target creating a data source with the same text/voice based way.<br />
                        IV: Enable creation of data sources populating data from API endpoints.<br />
                        V: Support creating a full application with custom PTAH defined components and data sources.<br />
                    </p>

                </div>
            </Modal>

            <div className="ptaa-header">
                <Logo height="16px" width="16px" />
                <div className="name">PTAA</div>

                <div className="ptaa-nav">
                    <ul>
                        <li onClick={ () => setOpenedModal(ModalTypes.LEGEND as any) }>color legend</li>
                        <li onClick={ () => setOpenedModal(ModalTypes.ABOUT as any) }>about ptaa</li>
                        <li>
                            { isLightTheme && <div onClick={onThemeToggle} className="theme-toggler dark-theme">
                                <MoonIcon width="12px" height="12px" /> dark
                            </div> }

                            { !isLightTheme && <div onClick={onThemeToggle} className="theme-toggler light-theme">
                                <SunIcon width="12px" height="12px" /> light
                            </div> }

                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
