import {Component} from "./definitions/types";
import {PtahSelect} from "./PtahSelect/PtahSelect";
import React, {useContext} from "react";
import {EditorContext} from "../App";

type PropTypes = {
    parent?: Component,
    definitions: Component[],
    onSelect: (component: Component) => void
};

export const SelectComponentFromDefinitions = (props: PropTypes) => {
    const context = useContext(EditorContext);

    const options = props.definitions
        .filter(definition => !props.parent || props.parent.canContain.includes(definition.name))
        .map(definition => ({ label: definition.name, value: definition })
    );

    const contextOptions = options.map(option => option.value.name);

    React.useEffect(() => {
        context.setOptions(contextOptions);
        console.log("+ Component from definition set OnMatch");

        context.onMatch((matchWord: any) => {
            const component = options.find((option: any) => {
                console.log(`option name "${option.label}" should match "${matchWord}"`);
                return option.label === matchWord;
            });
            if (component) {
                props.onSelect(component.value as Component);
            }
        });
    }, []);

    return <>
        <PtahSelect
            key="rootElementSelect"
            onChange={(selected: any) => props.onSelect(selected.value)}
            options={options}
        />
    </>
}
