import React, {useState} from 'react';
import './App.css';

import Editor from './Editor/Editor';

export const EditorContext = React.createContext({
    options: [],
    setOptions: (options: string[]) => { console.log("!!! Should not render. Context initial values."); },
    onMatch: (fn: (matchedWord: string) => void)  => { console.log("!!! Should not render. Context initial values."); },
    match: (matchedWord: string) => { console.log("!!! Should not render. Context initial values."); }
});

type PropsType = {
    children: any
}

let _onMatch: any;

const EditorContextProvider = (props: PropsType) => {
    const [options, setOptions] = useState([]);

    const context = {
        options,
        setOptions,
        onMatch: (fn: any) => {
            console.log("> New match function recieved", fn);
            _onMatch = fn;
        },
        match: (matchedWord: string) => {
            console.log("");
            console.log("@ match executed", `"${matchedWord}"`, "Reset matcher", _onMatch);
            if (_onMatch) {
                _onMatch(matchedWord)
                // _onMatch = null;
            };
        }
    };

    // console.log("RERENDER PROVIDER", options);

    return (
        <EditorContext.Provider value={context as any}>
            {props.children}
        </EditorContext.Provider>
    )
}

function App() {
  return (
    <div className="App">
        <EditorContextProvider>
            <Editor />
        </EditorContextProvider>
    </div>
  );
}

export default App;
