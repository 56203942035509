import React, {useState} from 'react';
import {Component} from "../definitions/types";
import {attributesToHash} from "../utils";

import "./styles.css";

type PropTypes = {
    steps: Component[]
}

export const RenderUI = (props: PropTypes) => {
    const [showOutline, setShowOutline] = useState(JSON.parse(localStorage.getItem("showOutline") || "false"));
    const [showName, setShowName] = useState(JSON.parse(localStorage.getItem("showName") || "false"));
    const [showDarkBackground, setShowDarkBackground] = useState(JSON.parse(localStorage.getItem("showBg") || "false"));

    const onShowOutline = (e: any) => {
        setShowOutline(!showOutline);
        localStorage.setItem("showOutline", JSON.stringify(!showOutline));
    }
    const onShowNames = () => {
        setShowName(!showName);
        localStorage.setItem("showName", JSON.stringify(!showName));
    }
    const onUseDarkBackground = () => {
        setShowDarkBackground(!showDarkBackground);
        localStorage.setItem("showBg", JSON.stringify(!showDarkBackground));
    }

    const render = (steps: Component[]) => {
        if (steps.length === 0) {
            return null;
        }

        const rootComponent = steps.find((component: Component) => component.parent === undefined);
        const childComponents = steps.filter((component: Component) => component.parent !== undefined);

        const renderSingleComponent = (component: Component, allComponents: Component[]) => {

            const children = allComponents
                .filter(allComp => {
                    // @ts-ignore
                    return allComp.parent.id === component.id;
                })
                .map(component => renderSingleComponent(component, allComponents));

            const result = component.render(children as any, attributesToHash(component.attributes), component.id);
            return result;
        }

        const result = renderSingleComponent(rootComponent as Component, childComponents);

        return <span key={Math.random()} className="rendered-component">{ result }</span>
    }

    let previewClassNames = "render-preview";

    if (showOutline) {
        previewClassNames += " show-outline";
    }

    if (showName) {
        previewClassNames += " show-name";
    }

    if (showDarkBackground) {
        previewClassNames += " show-dark-background";
    }

    return (
        <div className="render-ui">
            <div className={previewClassNames}>
                { render(props.steps) }
            </div>
            <div className="render-tools">
                <label><input checked={showOutline} type="checkbox" onChange={onShowOutline}/> Show outline</label>
                <label><input checked={showName} type="checkbox" onChange={onShowNames}/> Show names</label>
                <label><input checked={showDarkBackground} type="checkbox" onChange={onUseDarkBackground}/> Use dark background</label>
            </div>
        </div>
    );
}
