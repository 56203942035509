import {Component} from "./definitions/types";
import {PtahSelect} from "./PtahSelect/PtahSelect";
import React, {useContext, useEffect} from "react";
import {getComponentTypeAndName} from "./utils";
import {EditorContext} from "../App";

export const SelectExistingComponent = (props: { components: Component[], onSelect: (component: Component) => void }) => {
    const context = useContext(EditorContext);

    const options = props.components
        .filter(definitions => definitions.canContain.length !== 0)
        .map((component: Component) => {
            return { label: getComponentTypeAndName(component), value: component }
        });

    useEffect(() => {
        context.setOptions( options.map(option => option.label));
        console.log("+ Component from existing set OnMatch");
        context.onMatch((matchWord: any) => {
            const component = options.find((option: any) => option.label === matchWord);
            if (component) {
                props.onSelect(component.value as Component);
            }
        });
    }, []);

    return <>
        <PtahSelect
            key="rootElementSelect"
            onChange={(selected: any) => props.onSelect(selected.value)}
            options={options}
        />
    </>
}

