import React from 'react';

import "./styles.css";

import { ReactComponent as UiIcon } from "./assets/box-plot-line.svg";
import { ReactComponent as CodeIcon } from "./assets/align-left-text-line.svg";

export enum PreviewTypes {
    UI = 1,
    CODE
}

export const Tools = (props: {selected: PreviewTypes, onChange: (type: PreviewTypes) => void}) => {
    const selectedDisplayType = props.selected;
    const getClassName = (type: PreviewTypes) => selectedDisplayType === type ? "selected" : ""

    return (
        <ul className="tools">
            <li
                className={getClassName(PreviewTypes.UI)}
                onClick={ () => props.onChange(PreviewTypes.UI) }
            >
                <UiIcon width="24" />
            </li>

            <li
                className={getClassName(PreviewTypes.CODE)}
                onClick={ () => props.onChange(PreviewTypes.CODE) }
            >
                <CodeIcon width="24" />
            </li>
        </ul>
    )
}
