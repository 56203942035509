import {Attribute} from "./types";

export const name:Attribute = {
    name: "name",
    required: true,
    prefix: "named"
}

export const text:Attribute = {
    name: "text",
    required: true,
    prefix: "with content/text"
}

export const label:Attribute = {
    name: "label",
    required: true,
    prefix: "with label"
}

export const type:Attribute = {
    name: "type",
    required: true,
    prefix: "of type"
}

export const classes:Attribute = {
    name: "class names",
    required: false,
    prefix: "with class name/s"
}
