import {Component} from "./definitions/types";
import React, {useState} from "react";
import {SelectComponentFromDefinitions} from "./SelectComponentFromDefinitions";
import {FillComponentsAttributes} from "./FillComponentsAttributes";
import {uuidv4} from "./utils";

type PropTypes = {
    definitions: Component[],
    onComplete: (component: Component) => void
}

export const CreateTheRootComponent = (props: PropTypes) => {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const onComplete = (component: Component) => {
        component.id = uuidv4();
        props.onComplete(component);
    }

    return <>
        I want to have a&nbsp;

        { !selectedComponent
            ? <SelectComponentFromDefinitions
                definitions={props.definitions}
                onSelect={(component) => setSelectedComponent(component as any)}
            />
            : <span className="component-type">{(selectedComponent as any).name}</span>
        }

        { !!selectedComponent && (
            <FillComponentsAttributes
                component={selectedComponent as any}
                onComplete={onComplete}
            />
        )}
    </>
}

