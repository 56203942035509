import React from 'react';
import {Component} from "../definitions/types";
import {attributesToHash} from "../utils";
import { renderToString } from 'react-dom/server';

// @ts-ignore
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import "./styles.css";

function formatHTML(html: string) {
    var indent = '\n';
    var tab = '\t'; // step to indent
    var i = 0;
    var pre: { indent: string; }[] = [];

    html = html.replaceAll("<!-- -->", "");

    html = html
        .replace(new RegExp('<pre>((.|\\t|\\n|\\r)+)?</pre>'), function (x) {
            // @ts-ignore
            pre.push({ indent: '', tag: x });
            return '<--TEMPPRE' + i++ + '/-->'
        })
        .replace(new RegExp('<[^<>]+>[^<]?', 'g'), function (x) {
            var ret;
            // @ts-ignore
            var tag = /<\/?([^\s/>]+)/.exec(x)[1];
            var p = new RegExp('<--TEMPPRE(\\d+)/-->').exec(x);

            if (p)
                { // @ts-ignore
                    pre[p[1]].indent = indent;
                }

            if (['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'menuitem', 'meta', 'param', 'source', 'track', 'wbr'].indexOf(tag) >= 0) // self closing tag
                ret = indent + x;
            else {
                if (x.indexOf('</') < 0) { //open tag
                    if (x.charAt(x.length - 1) !== '>')
                        ret = indent + x.substr(0, x.length - 1) + indent + tab + x.substr(x.length - 1, x.length);
                    else
                        ret = indent + x;
                    !p && (indent += tab);
                }
                else {//close tag
                    indent = indent.substr(0, indent.length - 1);
                    if (x.charAt(x.length - 1) !== '>')
                        ret =  indent + x.substr(0, x.length - 1) + indent + x.substr(x.length - 1, x.length);
                    else
                        ret = indent + x;
                }
            }
            return ret;
        });

    for (i = pre.length; i--;) {
        // @ts-ignore
        html = html.replace('<--TEMPPRE' + i + '/-->', pre[i].tag.replace('<pre>', '<pre>\n').replace('</pre>', pre[i].indent + '</pre>'));
    }

    return html.charAt(0) === '\n' ? html.substr(1, html.length - 1) : html;
}

type PropTypes = {
    steps: Component[]
}

export const RenderCode = (props: PropTypes) => {

    const render = (steps: Component[]) => {
        if (steps.length === 0) {
            return null;
        }

        const rootComponent = steps.find((component: Component) => component.parent === undefined);
        const childComponents = steps.filter((component: Component) => component.parent !== undefined);

        const renderSingleComponent = (component: Component, allComponents: Component[]) => {

            const children = allComponents
                .filter(allComp => {
                    // @ts-ignore
                    return allComp.parent.id === component.id;
                })
                .map(component => renderSingleComponent(component, allComponents));

            const result = component.render(children as any, attributesToHash(component.attributes), component.id);
            return result;
        }

        const result = renderSingleComponent(rootComponent as Component, childComponents);

        return <span key={Math.random()} className="rendered-component">{ result }</span>
    }

    return (
        <div className="render-code">
            <SyntaxHighlighter
                language="html"
                showLineNumbers={true}
                wrapLongLines={true}
            >
                { formatHTML(renderToString(<> {render(props.steps)} </>)) }
            </SyntaxHighlighter>
        </div>
    );
}
