import { Component } from "./types";
import React from 'react';
import {classes, name, text, label, type} from "./common";

export const ClarityModal: Component = {
    name: "Model",
    canContain: ["Container", "Form", "Header", "Footer"],
    attributes: [name],
    render: (children, attributes: any) => {
        return (
            <div id={attributes.name}>
                <div className="modal static">
                    <div className="modal-dialog modal-md" role="dialog" aria-hidden="true">
                        <div className="modal-content">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop static" aria-hidden="true"></div>
            </div>
        )
    }
}

export const ClarityModalHeader: Component = {
    name: "Header",
    canContain: [],
    attributes: [name, text],
    render: (children, attributes: any) => {
        return (
            <div id={attributes.name} className="modal-header">
                <button aria-label="Close" className="close" type="button">
                    x
                </button>
                <h3 className="modal-title">{attributes.text}</h3>
            </div>
        )
    }
}

export const ClarityModalFooter: Component = {
    name: "Footer",
    canContain: ["Button"],
    attributes: [name],
    render: (children, attributes: any) => {
        return (
            <div id={attributes.name} className="modal-footer">
                {children}
            </div>
        )
    }
}

export const ClarityButton: Component = {
    name: "Button",
    canContain: [],
    attributes: [name, text],
    render: (children, attributes: any) => {
        return (
            <button id={attributes.name} className="btn btn-primary" type="button">{attributes.text}</button>
        )
    }
}

export const ClarityForm: Component = {
    name: "Form",
    canContain: ["Input", "Checkbox"],
    attributes: [name, text],
    render: (children, attributes: any) => {
        return (
            <form id={attributes.name} className="clr-form">{children}</form>
        )
    }
}

export const ClarityInput: Component = {
    name: "Input",
    canContain: [],
    attributes: [name, label],
    render: (children, attributes: any) => {
        return (
            <div id={attributes.name} className="clr-form-control">
                <label htmlFor="example" className="clr-control-label">{attributes.label}</label>
                <div className="clr-control-container">
                    <div className="clr-input-wrapper">
                        <input type={attributes.type} id="example" placeholder="Example Input" className="clr-input" />
                    </div>
                </div>
            </div>
        )
    }
}

export const ClarityCheckbox: Component = {
    name: "Checkbox",
    canContain: [],
    attributes: [name, label],
    render: (children, attributes: any) => {
        return (
            <div id={attributes.name} className="clr-form-control">
                <div className="clr-control-container">
                    <div className="clr-checkbox-wrapper">
                        <input name="test2" type="checkbox" value="option1" id="clr-form-control-1" />
                        <label className="clr-control-label" htmlFor="clr-form-control-1">{attributes.label}</label>
                    </div>
                </div>
            </div>
        )
    }
}
